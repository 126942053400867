import React from 'react';
import styled from 'styled-components'

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

const Body = styled.div`
    min-height: calc(100vh - 6rem);
    display:flex;
    justify-content:center;
    align-items: center;
    @media (max-width: 768px) {
      align-items: flex-start;
      min-height: 0;
    }
`
const AboutForm = styled.div`
    width: 580px;
    height: 350px;
    margin-top: 1rem;
    padding-bottom: 20%;
    @media (max-width: 768px) {
      width: 100%;
      padding: 1rem;
    }
`

function About() {
  return (
    <Body>
        <AboutForm>
        <h1 className="bp3-heading">About</h1>
        <p className="bp3-text-large">Every technology is driven by development community. It is powered by people's ideas and contribution.</p>
        <p className="bp3-text-large">dev-labs.io project serves two goals: </p>
        <ul>
        <li className="bp3-text-large">make <strong>open source</strong> a word in ServiceNow development community</li>
        <li className="bp3-text-large">bring <strong>modern web development</strong> contepts to ServiceNow projects</li>
        </ul>
        <p className="bp3-text-large">We believe that Open Source and Modern Web Development can significantly boost ServiceNow development world. And it's not just about modern technologies, but about people, values, and making the world a better place. </p>
        <p className="bp3-text-large">We are here to make a difference.</p>
        <p className="bp3-text-muted bp3-text-small" style={{marginTop:"16px"}}>Question or comments? Send us an email <a href="mailto:support@dev-labs.io">support@dev-labs.io</a></p>
        </AboutForm>
    </Body>
  );
}

export default About;
