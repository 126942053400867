import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Cards from './Cards'
import axios from 'axios'
import {
  Button,
  ButtonGroup,
  InputGroup,
  Spinner,
  NonIdealState,
} from "@blueprintjs/core"
import './Content.css'

const Summary = styled.div`
    text-align: center;
    width:100%;
    margin-top:26px;
    color: rgba(92,112,128,0.52);
    @media (max-width: 768px) {
      margin-top: 1rem;
    }
`

const ContentStyle = styled.div`
  min-height: calc(100vh - 12rem);
  margin: 26px 0;
  display: grid;
  grid-template-columns: minmax(24px,auto) minmax(auto,1200px) minmax(24px,auto);
  grid-template-rows: 40px auto;
  grid-gap:26px;
  background-color: #f4f8fb;
  @media (max-width: 768px) {
    display: block;
    margin-top: .5rem;
  }
`
const ContentWrapper = (props) => {
  return (
    <ContentStyle>
      <div></div>
      {props.children}
      <div></div>
    </ContentStyle>
  )
}

const SearchRow = styled.div`
  display:flex;
  justify-content:center;
  @media (max-width: 768px) {
    display: block;
  }
`

const SubmitButton = styled.div`
    display: none;
    @media (max-width: 768px) {
      display: block;
      padding: .5rem;
    }
`

const LoadingIndicator = styled.div`
    padding: 2rem;
`

const Content = ({ history }) => {
  const [repos, setRepos] = useState([]);
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('by-stars')
  const [stats, setStats] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const setPage = (url) => {
    history.push(url)
  }

  useEffect(() => {
    axios.get('/api/x_elsr_dev_labs_io/dev_labs_io/repos')
      .then(res => {
        setRepos(res.data.result)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setError(true)
      })
  }, [])

  useEffect(() => {
    const updatedStats = {
      projects: repos.length,
      contributors: 0,
      stars: 0,
    }
    repos.forEach(repo => {
      updatedStats.contributors += parseInt(repo.contributors)
      updatedStats.stars += parseInt(repo.stars)
    })
    setStats(updatedStats)
  }, [repos])

  let filteredRepos = repos

  if (searchQuery) {
    console.log(searchQuery)
    filteredRepos = repos.filter(repo => {
      let contains = (field) => field.toLowerCase().includes(searchQuery.toLowerCase())
      return contains(repo.name) || contains(repo.description) || contains(repo.repo_tag)
    })
  }

  let sortedRepos = filteredRepos.sort((a, b) => {
    switch (sortBy) {
      case 'by-stars':
        return b.stars - a.stars
      case 'most-recent':
        return b.added_at.localeCompare(a.added_at)
      case 'most-active':
        return b.last_commit.localeCompare(a.last_commit)
      default:
        return 0
    }
  })

  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSort = (sorter) => setSortBy(sorter)

  const isSortedBy = (sorter) => sortBy === sorter

  return (
    <>
      <Summary> {stats.projects} projects, {stats.contributors} contributors and {stats.stars} stars</Summary>
      <ContentWrapper>
        <SearchRow>
          <div className="search-container">
            <InputGroup
              large
              placeholder="Search projects..."
              leftIcon="search"
              value={searchQuery}
              onChange={handleSearchInput}
            // rightElement={(<Button minimal icon="arrow-right" intent="primary" />)}
            />
          </div>
          <ButtonGroup className="sorting-btns" >
            <Button large icon="chart" active={isSortedBy('most-active')} onClick={() => handleSort('most-active')}>most active</Button>
            <Button large icon="star" active={isSortedBy('by-stars')} onClick={() => handleSort('by-stars')}>by stars</Button>
            <Button large icon="automatic-updates" active={isSortedBy('most-recent')} onClick={() => handleSort('most-recent')}>most recent</Button>
          </ButtonGroup>
        </SearchRow>
        <div></div>
        <div></div>
        <SubmitButton>
          <Button fill large intent="success" icon="projects" onClick={() => setPage('/submit')} >Submit Project</Button>
        </SubmitButton>
        <div>
          {loading &&
            <LoadingIndicator>
              <Spinner size={50} />
            </LoadingIndicator>}

          {error &&
            <NonIdealState
              icon="error"
              title="Weird error"
              description={<p>For some reason, loading repos failed.<br /> Try coming back later.</p>}
            />
          }
          <Cards repos={sortedRepos} sortBy={sortBy} />
        </div>
      </ContentWrapper>
    </>
  );
}

export default Content;
