import App from './App';
import axios from 'axios'
import CONFIG from './config'
import { FocusStyleManager } from "@blueprintjs/core";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

const { instanceURL } = CONFIG

axios.defaults.baseURL = instanceURL
axios.defaults.headers.post['Content-Type'] = 'application/json'
 
FocusStyleManager.onlyShowFocusOnTabs();



ReactDOM.render(<App />, document.getElementById('root'));
