import React from 'react';
import styled from 'styled-components'
import logo from './logo.png'
import { Button, Classes } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'


const HeaderBlock = styled.div`
    padding-left:26px;
    width:100%;
    height: 52px;
    background-color: #293742;
    display:flex;
    align-items: center;
    box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 0 0 rgba(16,22,26,0), 0 1px 1px rgba(16,22,26,.2);
    z-index:999;
    @media (max-width: 768px) {
      padding: 2rem 2rem 1rem;
    }

`

const HeaderSubBlock = styled.div`
   display: none;
   @media (max-width: 768px) {
      color: #fff;
      background-color: #293742;
      display: block;
      padding: .5rem 2rem 1rem;
   }
`


const Title = styled.div`
    font-weight:600;
    font-size:24px;
    color:#f5f8fa;
    margin-right:8px;
    cursor:pointer;
    @media (max-width: 768px) {
      font-size: 26px;
    }
`

const Divider = styled.div`
    margin: 0 10px;
    border-left: 1px solid hsla(0,0%,100%,.15);
    height: 30px;
    @media (max-width: 768px) {
      display: none;
    }
`

const Description = styled.span`
    font-size: 16px;
    color:#f5f8fa;
    margin-right:24px;
    @media (max-width: 768px) {
       display: none;
    }
`

const RightMenu = styled.div`
    margin-left: auto;
    padding-left:16px;
    padding-right: 16px;
`

const SubmitButton = styled.div`
    @media (max-width: 768px) {
       display: none;
    }
`


const Header = ({ history }) => {
   const setPage = (url) => {
      history.push(url)
   }

   return (
      <>
         <div className={Classes.DARK}>
            <HeaderBlock>
               <img alt="logo" src={logo} width="32" style={{ marginRight: "6px", cursor: "pointer" }} onClick={() => setPage('/')} />
               <Title onClick={() => setPage('/')}>dev-l@bs</Title>
               <Divider />
               <Description>
                  Catalog of open source projects for ServiceNow
               </Description>
               <SubmitButton>
                  <Button intent="success" icon="projects" onClick={() => setPage('/submit')}>Submit Project</Button>
               </SubmitButton>
                <RightMenu>
                  <Button onClick={() => setPage('/about')}>About</Button>
               </RightMenu>
            </HeaderBlock>
            <HeaderSubBlock>
                  Catalog of open source projects for ServiceNow
            </HeaderSubBlock>
         </div>
      </>
   );
}

export default withRouter(Header);
