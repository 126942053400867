import React from 'react';
import styled from 'styled-components'
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import {
  Icon,
  Tag,
} from "@blueprintjs/core"

const Container = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 270px);
    @media (max-width: 768px) {
      display: block;
    }
`
const Card = styled.div`
  width: 270px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  border-radius: 3px;
  background: ${props => props.tag!=='web' ? "white":"white"};
  color:#8093A7;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  height:150px;
  position: relative;
  &:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.2);
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: auto;
    margin: .5rem;
  }
`
const Separator = styled.div`
    width:256px;
    border-top:1px solid rgba(16, 22, 26, 0.1);
    margin-left:4px
`
const Description = styled.div`
  padding:8px;
  color:#617080
`
const CardFooter = styled.div`
  display:flex;
  padding-left:8px;
  padding-top:36px;
  font-size:12px;
  align-items:center;
  width:100%;
  position:absolute;
  bottom:4px;
`

function parseDateTime(datetime) {
  // parse date time string
  let year = parseInt(datetime.slice(0, 4))
  let month = parseInt(datetime.slice(5, 7)) - 1
  let date = parseInt(datetime.slice(8, 10))
  let hours = parseInt(datetime.slice(11, 13))
  let minutes = parseInt(datetime.slice(14, 16))
  let seconds = parseInt(datetime.slice(17, 19))

  // convert input UTC datetime to local time Date object
  const dtObj = new Date(Date.UTC(year, month, date, hours, minutes, seconds))

  return dtObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
}

const Cards = ({ repos }) => {

  const CardDataGrid = repos.map((item) => {
    const updatedAtDate = parseDateTime(item.last_commit)

    return (
      <Card key={item.repo} onClick={() => window.open(item.repo, "_blank")} tag={item.repo_tag}>
        <div style={{ padding: "8px", display: "flex", alignItems: "center" }}>
          <div style={{ fontWeight: "600", fontSize: "20px", color: "#182026" }} className="bp3-text-overflow-ellipsis">{item.name}</div>
          <div style={{ marginLeft: "auto" }}>
            {item.repo_tag === 'new' ? <Tag intent="success">new</Tag> : ''}
            {item.repo_tag === 'react' ? <Tag intent="primary">react</Tag> : ''}
            {item.repo_tag === 'web' ? <Tag style={{"background":"red"}}>Web</Tag> : ''}
          </div>
        </div>
        <Separator />
        {item.description.length<=130 && <Description>{item.description}</Description>}
        {item.description.length>130 && <Description>{item.description.substring(0,125)+'...'}</Description>}
        <CardFooter>
        { item.repo_tag!=='web' && <>
          <Icon icon="star" iconSize="12" /> <div style={{ marginLeft: "4px", marginRight: "8px" }}>{item.stars}</div>
          <Icon icon="eye-open" iconSize="12" /> <div style={{ marginLeft: "4px", marginRight: "8px" }}> {item.watchers}</div>
          <Icon icon="people" iconSize="12" /> <div style={{ marginLeft: "4px", marginRight: "8px" }}>{item.contributors}</div>
          </>
        }  
          <div style={{ marginLeft: "auto", paddingRight: "8px" }}>Updated on {updatedAtDate}</div>

        </CardFooter>
      </Card>
    )
  })

  return (
    <Container>
      {CardDataGrid}
    </Container>
  );
}

export default Cards;
