import axios from 'axios'
import React, { useState } from 'react';
import styled from 'styled-components'
import {
  Button,
  Icon,
  InputGroup,
  NonIdealState,
} from "@blueprintjs/core"

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import './Submit.css'

const Body = styled.div`
    background-color: #f4f8fb;
    z-index:-1;
    width:100%;
    height: 90vh;
    display:flex;
    justify-content:center;
    align-items: center;
    @media (max-width: 768px) {
      align-items: flex-start;
    }
`
const SubmitForm = styled.div`
    width: 580px;
    margin-top: 1rem;
    padding-bottom: 20%;
    @media (max-width: 768px) {
      width: 100%;
      padding: 1rem;
    }
`

const SubmitButton = styled.div`
    @media (max-width: 768px) {
      float: right;
    }
`

function hasEmptyValue(obj) {
  for (let value in obj) {
    if (obj.hasOwnProperty(value)) {
      if (!obj[value]) return true
    }
  }
  return false
}

function Submit({ history }) {
  const [values, setValues] = useState({
    repo: '',
    email: '',
    stack: '',
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const submit = () => {
    setLoading(true)
    axios.post('/api/x_elsr_dev_labs_io/dev_labs_io/submit', values)
      .then(res => {
        setSuccess(true)
      })
      .catch(err => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const returnHome = () => {
    history.push('/')
  }

  const disabled = hasEmptyValue(values)

  return (
    <Body>
      <SubmitForm>
        {!error && !success &&
          <>
            <h1 className="bp3-heading">Submit Project</h1>
            <p className="bp3-text-large">It is simple - put your project in a public GitHub repository and let us know.</p>
            <p className="bp3-text-muted bp3-text-small"> Usually the project will become available on the portal in 24 hours.</p>
            <InputGroup name="repo" onChange={handleChange} className="field top-ten" large placeholder="GitHub repo link" leftIcon="git-repo" />
            <InputGroup name="email" onChange={handleChange} className="field" large placeholder="Your email" leftIcon="envelope" />
            <InputGroup name="stack" onChange={handleChange} className="field" large placeholder="Technology stack (ServiceNow native app, react, angular etc.)" leftIcon="code" />
            <SubmitButton>
              <Button loading={loading} disabled={disabled} onClick={submit} large intent="primary">Submit</Button>
            </SubmitButton>
          </>
        }
        {error &&
          <NonIdealState
            icon="error"
            title="Weird error"
            description={<p>For some reason, your submit request failed.<br /> Try coming back later.</p>}
            action={<Button
              text="Browse other projects"
              intent="primary"
              onClick={returnHome}
            />}
          />
        }

        {success &&
          <NonIdealState
            icon={<Icon icon="tick-circle" intent="success" iconSize={100} />}
            title="Success"
            description={<p>We received your submit request.<br /> Your project will soon be added to the list.</p>}
            action={<Button
              text="Browse other projects"
              intent="primary"
              onClick={returnHome}
            />}
          />
        }
      </SubmitForm>


    </Body>
  );
}

export default Submit;
