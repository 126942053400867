import React from 'react';
import Header from './Components/Header/Header'
import Content from './Components/Content/Content'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Submit from './Components/Submit/Submit'
import About from './Components/About/About'
import styled from 'styled-components'
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import './App.css'

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

const HeaderAndPage = styled.div`
    flex-grow: 1;
`

const Footer = styled.div`
    text-align: center;
    width: 100%;
    color: rgba(92,112,128,0.52);
    height: 4rem;
    background-color: #f4f8fb;
    @media (max-width: 768px) {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 1rem 0 3rem;
    }
`

function App() {
  return (
    <BrowserRouter>
      <FlexContainer>
        <HeaderAndPage>
          <Header />
          <Switch>
            <Route path="/submit" component={Submit} />
            <Route path="/about" component={About} />
            <Route path="/" component={Content} />
          </Switch>
        </HeaderAndPage>
        <Footer>
          Everyone can make a difference
    </Footer>
      </FlexContainer>
    </BrowserRouter>
  );
}

export default App;
